import React,{useState} from 'react';
import './contact.scss';
import '../../../components/Header/Header.style.scss'
import Footer from '../../../components/Footer/Footer'
import { Link } from "gatsby";
import axios from "axios";

const Contact = () => {
    const [status, setStatus] = useState({
        submitted: false,
        submitting: false,
        info: { error: false, msg: null },
      })
      const [inputs, setInputs] = useState({
        email: '',
        message: '',
        name: "",
        tel:"",
      })
      const handleServerResponse = (ok, msg) => {
        if (ok) {
          setStatus({
            submitted: true,
            submitting: false,
            info: { error: false, msg: msg },
          })
          setInputs({
            email: '',
            message: '',
            name: "",
            tel:"",
          })
        } else {
          setStatus({
            info: { error: true, msg: msg },
          })
        }
      }
      const handleOnChange = (e) => {
        e.persist()
        setInputs((prev) => ({
          ...prev,
          [e.target.id]: e.target.value,
        }))
        setStatus({
          submitted: false,
          submitting: false,
          info: { error: false, msg: null },
        })
      }
      const handleOnSubmit = (e) => {
        e.preventDefault()
        setStatus((prevStatus) => ({ ...prevStatus, submitting: true }))
        axios({
          method: 'POST',
          url: 'https://formspree.io/f/mdopylbv',
          data: inputs,
        })
          .then((response) => {
            handleServerResponse(
              true,
              'お問い合わせ有り難うございます\n順次返信いたしますので、今しばらくお待ちください。'
            )
          })
          .catch((error) => {
            handleServerResponse(false, error.response.data.error)
          })
      }
    
    return (
        <section className="contact_section">
            <header  className="header ph content_header">
            <div className="header_logo_box">
                <img className="header_logo img_color" src="https://spin-dd.com/wp-content/uploads/2020/03/spin_logo_w.png" alt="会社のロゴです" />
                <nav className="nav-container">
                    <ul className="ui-container">
                    <li className="nav-item home-visited"><button><Link to ="/">HOME</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/about">ABOUT</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/services">SERVICES</Link></button></li>
                    <li className="nav-item home-visited"><button>WORKS</button></li>
                    <li className="nav-item home-visited"><button>BLOG</button></li>
                    <li className="nav-item home-visited"><button><Link to ="/contact">CONTACT</Link></button></li>
                    </ul>
                </nav>
                </div>
            </header>
            <h2 className="contact-title-info">Contact Info</h2>
            <div className="container-container-form">
            <div className="contact-container-info">
                <div className="contact-container-item">
                    <p className="contact-address">Address</p>
                    <p className="contact-address-text">〒113-0033 東京都文京区本郷1-21-5 第四太平ビル2階</p>
                </div>
                <div className="contact-container-item">
                    <p className="contact-phone">Phone</p>
                    <p className="contact-phone-text">(03) 5615 8845</p>
                </div> 
                <div>
                    <p className="contact-fax">Fax</p>
                    <p className="contact-fax-text">(03) 5615 8846</p>
                </div>
                <div className="contact-container-item">
                    <p className="contact-email">Email</p>
                    <p className="contact-email-text">contact@spin-dd.com</p>
                </div>
            </div>
                <div className="content-middle-container">        
                    <form action="https://formspree.io/f/mdopylbv" onSubmit={handleOnSubmit}>
                        <div className="contact-form-d-flex">
                        <div className="contact-form--container-left">
                            <h6>お名前</h6>
                            <input id="name" onChange={handleOnChange} value={inputs.name} type="text" required placeholder="名前" />
                            <h6>Email</h6>
                            <input id="email" type="email" name="_replyto" onChange={handleOnChange} required value={inputs.email}  placeholder="(例) spin-dd@spin .com" />
                            <h6>電話番号</h6>
                            <input id="tel" onChange={handleOnChange} value={inputs.tel} type="tel" placeholder="(例) 090-0000-1111" />
                        </div>
                        <div className="contact-form--container-right">
                            <textarea className="contact-textarea" name="message" id="message" cols="30" rows="10" placeholder="メッセージ内容" onChange={handleOnChange} required value={inputs.message}></textarea>
                        </div>
                        </div>
                        <button disabled={status.submitting} type="submit" className="submit">{!status.submitting? !status.submitted? '送信': '送信完了': '送信中...'}</button>
                    </form>
                    {status.info.error && (
                        <div className="error">Error: {status.info.msg}</div>
                    )}
                    {!status.info.error && status.info.msg && <p className="submit_message_info">{status.info.msg}</p>}
                </div>
            </div>
            <iframe className="g_map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3251.11088509294!2d139.75319863669995!3d35.704991103902955!2m3!1f0!2f0!3f0!3m2!1i1024!2widi768!4f13.1!3m3!1m2!1s0x6019135d2599a6bf%3A0x7a43f5f7989ca678!2z5qCq5byP5Lya56S-U1BJTiBEJkQ!5e0!3m2!1sja!2sjp!4v1608361266432!5m2!1sja!2sjp" width="100%" height="400px" >mfkdsmakdl</iframe>
            <Footer />
        </section>
    )
}
export default Contact; 